<template>
  <v-container class="pa-0 ma-0">
    <div style="text-align: center" v-if="loading" class="mt-2">
      <v-progress-circular indeterminate color="#fefefe"></v-progress-circular>
    </div>
    <div v-else class="ble_result_container">
      <h5 class="no_data mt-1 mb-2 d-flex justify-center">
        <v-icon style="font-size: 1rem">mdi-heart-outline</v-icon>
        <span class="d-print-none" style="line-height: 25px">{{
          episode.datapoints && episode.datapoints.length
        }}</span>
        <verification-icon
          :font-size="'25px'"
          :show-text="true"
          class="ml-3"
          v-if="episode.verification"
          :category="episode.verification.category"
        />
      </h5>
      <v-btn icon fixed style="top: 70px; z-index: 20" @click="goBackToProfile">
        <v-icon color="white">mdi-arrow-left</v-icon>
      </v-btn>
      <h2 class="text-center mb-2">{{ episode.name }}</h2>
      <h4
        class="text-center mb-2"
        v-if="episode.config && episode.config.deviceName"
      >
        {{ episode.config.deviceName }}
      </h4>
      <h5 class="no_data" style="font-size: 80%">
        {{ $t("common.date") }} {{ formatDate(episode.date) }}
      </h5>

      <div class="mt-3">
        <h3>
          <v-icon>mdi-clipboard-outline</v-icon>
          {{ $t("common.patient") }}:
        </h3>
        <patient
          ref="PatientSelector"
          v-model="episode.patientId"
          :editable="!episode.patientId"
          @selectedProfile="onSelectedProfile"
        />
      </div>

      <div class="mt-5">
        <h3>
          <v-icon>mdi-chart-arc</v-icon>
          {{ $t("common.averageStats") }}:
        </h3>
        <table>
          <tr>
            <th rowspan="2" style="border-right: 1px solid #e0e0e0; width: 60%">
              <h3 class="name">
                <v-icon>mdi-heart</v-icon>
                {{ $t("common.heartRate") }}:
              </h3>
              <h1 class="value">
                {{ stats.pulse || "---" }}<span class="sub_text">bpm</span>
              </h1>
            </th>
            <td class="pr-2 pl-1">
              <h3 class="name right">{{ $t("common.sys") }}:</h3>
            </td>
            <td>
              <h1 class="value sub">{{ stats.sys || "---" }}</h1>
            </td>
          </tr>
          <tr>
            <td class="pr-2 pl-1">
              <h3 class="name right">{{ $t("common.dias") }}:</h3>
            </td>
            <td>
              <h1 class="value sub">{{ stats.dia || "---" }}</h1>
            </td>
          </tr>
        </table>
      </div>

      <div class="html2pdf__page-break" />
      <div class="mt-5">
        <h3>
          <v-icon>mdi-chart-line</v-icon>
          {{ `${$t("common.chart")}` }}:
        </h3>
        <div class="table">
          <highcharts :options="chartOptions"></highcharts>
          <stats-box-2 v-model="stats2" />
        </div>
        <div class="html2pdf__page-break" />
        <div class="table">
          <highcharts :options="chartPieOptions"></highcharts>
          <div id="custom-legend">
            <ul>
              <li
                v-for="(point, index) in chartPieOptions.series[0].data"
                :key="index"
              >
                <span
                  :style="{ backgroundColor: point.color }"
                  class="legend-color"
                ></span>
                <span class="legend-label"
                  >{{ point.name }} : {{ point.y || 0 }} %</span
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="html2pdf__page-break" />
        <div class="table">
          <highcharts :options="chartBubbleOptions"></highcharts>
        </div>
      </div>

      <div class="mt-5">
        <h3>
          <v-icon>mdi-table</v-icon>
          {{ $t("common.datapoints") }}:
        </h3>
        <v-data-table
          :headers="headers"
          :items="datapoints"
          class="elevation-1"
          hide-default-footer
          disable-pagination
          mobile-breakpoint="0"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.pulse="{ item }">
            <v-icon style="font-size: 1rem">mdi-heart-outline</v-icon>
            {{ item.pulse }}
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.status="{ item }">
            <v-badge
              style="font-size: 10px"
              :color="
                item.status == 1
                  ? 'red'
                  : item.status == 2
                  ? 'orange'
                  : item.status == 3
                  ? 'green'
                  : 'cyan'
              "
            />
          </template>
        </v-data-table>

        <v-data-table
          v-if="errors.length"
          :headers="headersError"
          :items="errors"
          class="elevation-1"
          hide-default-footer
          disable-pagination
          mobile-breakpoint="0"
        >
        </v-data-table>
      </div>

      <div class="html2pdf__page-break" />
      <div class="mt-5">
        <h3>
          <v-icon>mdi-file-document-outline</v-icon>
          {{ $t("common.clinicalNote") }}:
        </h3>
        <div class="content_detail" v-html="episode.note"></div>
      </div>
      <div class="mt-5" v-if="episode.verification">
        <h3>
          <v-icon>mdi-file-document-outline</v-icon>
          {{ $t("common.doctorNote") }}:
        </h3>
        <div class="content_detail" v-html="episode.verification.note"></div>
      </div>
      <div class="html2pdf__page-break" />
      <span style="opacity: 0">THE END.</span>
    </div>
    <div class="d-flex mt-2 mb-1">
      <v-spacer />
      <v-btn
        @click="downloadExcel"
        class="mt-5 custom pa-6 pl-8 pr-8"
        style="float: right"
        v-if="!isNative"
      >
        <v-icon class="mr-2">mdi-download</v-icon>
        download data
      </v-btn>
      <v-spacer />
    </div>

    <v-btn
      icon
      color="white"
      style="top: 10px; z-index: 100; position: absolute; right: 65px"
      @click="callPhone(episode.patientId)"
      :style="{
        // right: isMobileVersion ? '5px' : '80px',
        // bottom: isMobileVersion ? '20px' : 'auto',
        // top: isMobileVersion ? 'auto' : '10px',
      }"
      v-if="episode && episode.patientId && episode.patientId != $phone"
    >
      <v-icon>mdi-phone</v-icon>
    </v-btn>
    <v-btn
      icon
      color="white"
      style="top: 10px; z-index: 100; position: absolute; right: 110px"
      :style="{
        // right: isMobileVersion ? '5px' : '140px',
        // bottom: isMobileVersion ? '70px' : 'auto',
        // top: isMobileVersion ? 'auto' : '10px',
      }"
      @click="
        $store.commit('Mobile/SET_MESSAGE_PHONE_STRING', episode.patientId)
      "
      v-if="episode && episode.patientId && episode.patientId != $phone"
    >
      <v-icon>mdi-message-text</v-icon>
      <LottieNewMessage :phone="episode.patientId" />
    </v-btn>
  </v-container>
</template>

<script>
import moment from "moment";
import Patient from "@/components/patient/Patient.vue";
import {
  pulseChartOptions,
  statsPieChartOptions,
  bubbleChartOptions,
} from "@/components/ChartOptions";
import { mapGetters } from "vuex";
import LottieNewMessage from "@/components/LottieNewMessage.vue";
import StatsBox2 from "@/components/StatsBox2.vue";
import { PieChartColorHexCode } from "@/plugins/constants";
import VerificationIcon from "@/views/monitor/components/VerificationIcon.vue";

export default {
  components: {
    Patient,
    LottieNewMessage,
    StatsBox2,
    VerificationIcon,
  },
  props: {
    episodeId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      offlineDetail: "Offline/offlineEpisodeDetail",
    }),
  },
  data() {
    return {
      loading: true,
      episode: {},
      errors: [],
      stats: {},
      stats2: {},
      chartOptions: {},
      chartPieOptions: {},
      chartBubbleOptions: {},
      datapoints: [],
      headers: [
        { text: "#", value: "ind", sortable: false },
        { text: this.$t("common.datetime"), value: "date", sortable: false },
        { text: this.$t("common.sys"), value: "sys", sortable: false },
        { text: this.$t("common.dias"), value: "dia", sortable: false },
        { text: this.$t("common.heartRate"), value: "pulse", sortable: false },
        { text: "", value: "status", sortable: false },
      ],
      headersError: [
        { text: "#", value: "ind", sortable: false },
        { text: this.$t("common.datetime"), value: "date", sortable: false },
        { text: this.$t("error.errorCode"), value: "message", sortable: false },
      ],
    };
  },
  mounted() {
    this.clean();
    this.renderBleAnimation();
    this.render();
    this.$store.commit("Scanner/SET_IS_MEASURING", false);
  },
  methods: {
    async goBackToProfile() {
      this.back();
    },
    downloadExcel() {
      this.downloadDatapointsAsExcel(this.episode, this.errors);
    },
    async renderVerification() {
      this.episode.verification =
        (await this.$dbGet(`episodes/${this.episode.id}/verification`)) || {};
      this.$forceUpdate();
    },
    async render() {
      var id = this.$route.params.id || this.episodeId || null;
      if (!id) return;
      this.loading = true;
      var episode = null;
      var offlineData = this.offlineDetail(id);
      if (offlineData) {
        console.log(">>>> Render offlineData", offlineData);
        console.log(">>>> this.$route.params.id", id);
        if (offlineData.id == id) {
          episode = offlineData;
          var { patientObject } = offlineData;
          await this.sleep(100);
          if (patientObject && this.$refs.PatientSelector) {
            this.$refs.PatientSelector.renderOfflinePatient(patientObject);
          }
        } else {
          episode = await this.$dbGet(`episodes/${id}`);
        }
      } else {
        episode = await this.$dbGet(`episodes/${id}`);
      }
      if (!episode) {
        this.loading = false;
        return;
      }
      this.errors = (await this.$dbGet(`episodesError/${id}`)) || [];
      this.episode = episode;
      this.episode.note = this.episode.note || this.$t("common.nodata");
      var { datapoints } = episode;
      datapoints = this.sortDatapoints(datapoints);
      this.calibAverageStats(datapoints);
      this.stats2 = this.calculateStats(datapoints);
      this.renderDataTable(datapoints);
      this.renderChart(datapoints);
      this.renderBubbleChart(datapoints);
      this.renderStatsPieChart();
      this.loading = false;
      this.subscribeWatchers();
    },
    async onSelectedProfile(patient = {}) {
      try {
        var { patientId, fullName } = patient;
        var { datapoints } = this.episode;
        var episodeName = (this.episode.name += `-${fullName}`);
        this.$dbSet(`episodes/${this.episode.id}/name`, episodeName);
        this.$dbSet(`episodes/${this.episode.id}/patientId`, patientId);
        this.$dbSet(
          `users/${this.$uid}/episodes/${this.episode.id}/name`,
          episodeName
        );
        this.$dbSet(
          `users/${this.$uid}/episodes/${this.episode.id}/patientId`,
          patientId
        );
        this.$dbSet(
          `users/${this.$uid}/episodes/${this.episode.id}/datacounts`,
          datapoints.length
        );
        // Ping Patient
        await this.sleep(1000);
        this.$dbSet(`patients/${patientId}/currentEpisodeId`, this.episode.id);
        await this.sleep(1000);
        this.$dbSet(`patients/${patientId}/currentEpisodeId`, "");
      } catch (err) {
        console.log("onPatientSelected Error! " + err.message);
      }
    },
    async subscribeWatchers() {
      var id = this.$route.params.id || null;
      if (!id) return;
      this.$dbWatcher(`episodes/${id}/datapoints`, (datapoints) => {
        if (!datapoints) return;
        datapoints = this.sortDatapoints(datapoints);
        this.calibAverageStats(datapoints);
        this.stats2 = this.calculateStats(datapoints);
        this.renderChart(datapoints);
        this.renderBubbleChart(datapoints);
        this.renderDataTable(datapoints);
        this.renderStatsPieChart();
      });
      this.$dbWatcher(`episodes/${id}/patientId`, (patientId) => {
        if (!patientId) return;
        this.episode.patientId = patientId;
      });
      this.$dbWatcher(`episodes/${id}/note`, (note) => {
        if (!note) return;
        this.episode.note = note;
      });
    },
    async renderDataTable(points = []) {
      this.datapoints = points.reverse().map((p, ind) => ({
        ...p,
        ind: ind + 1,
        date: moment(p.date).format("DD-MM-YYYY HH:mm"),
        status: this.calibDataSeverity(p),
      }));
    },
    async renderChart(points = []) {
      var pulses = [];
      var ranges = [];
      for (var point of points) {
        var timestamp = moment(point.date).valueOf();
        if (point.pulse) {
          pulses.push([timestamp, point.pulse]);
        }
        if (point.sys && point.dia) {
          ranges.push([timestamp, point.sys, point.dia]);
        }
      }

      this.chartOptions = {
        ...pulseChartOptions,
        series: [
          {
            name: this.$t("common.heartRate"),
            data: pulses,
            type: "spline",
            zIndex: 1,
            color: "#F06292",
            marker: {
              radius: 4,
              fillColor: "#e0e0e0",
              lineWidth: 2,
              lineColor: "#EC407A",
            },
          },
          {
            name: this.$t("common.bloodPressure"),
            data: ranges,
            type: "arearange",
            lineWidth: 0,
            linkedTo: ":previous",
            color: "#E1F5FE",
            fillOpacity: 0.7,
            zIndex: 0,
            marker: {
              radius: 3,
              fillColor: "#e0e0e0",
              lineWidth: 1,
              lineColor: "#039BE5",
              symbol: "circle",
              // enabled: false,
            },
          },
        ],
      };
    },
    async renderBubbleChart(points = []) {
      var data = points.map((i, ind) => ({
        ind,
        ...i,
        x: moment(i.date).day(),
        y: moment(i.date).hour(),
        z: Math.abs(i.sys),
        color: this.getDataSeverityColor(i),
        opacity: 1,
        marker: {
          lineWidth: 0,
          lineColor: null,
        },
      }));
      this.chartBubbleOptions = {
        ...bubbleChartOptions,
        series: [
          {
            data,
            marker: {
              fillOpacity: 0.6,
            },
            events: {
              click: (e) => {
                var p = e.point;
                var textString = `${this.$t("common.bloodPressure")}: ${
                  p.sys
                } - ${p.dia}, ${p.pulse} bpm\n${this.$t(
                  "common.datetime"
                )}: ${moment(p.date).format("HH:mm DD/MM/YYYY")}`;
                alert(textString);
              },
            },
          },
        ],
      };
    },
    async renderStatsPieChart() {
      this.chartPieOptions = {
        ...statsPieChartOptions,
        series: [
          {
            type: "pie",
            name: this.$t("common.bloodPressure"),
            data: [
              {
                name: this.$t("common.low"),
                y: this.stats2.low.percent,
                color: PieChartColorHexCode.Low,
              },
              {
                name: this.$t("common.normal"),
                y: this.stats2.normal.percent,
                sliced: true,
                selected: true,
                color: PieChartColorHexCode.Normal,
              },
              {
                name: this.$t("common.high"),
                y: this.stats2.high.percent,
                color: PieChartColorHexCode.High,
              },
              {
                name: this.$t("common.veryHigh"),
                y: this.stats2.veryHigh.percent,
                color: PieChartColorHexCode.SoHigh,
              },
            ],
          },
        ],
      };
    },
    async calibAverageStats(points = []) {
      this.stats = {
        pulse: this.calibAverage(points, "pulse"),
        sys: this.calibAverage(points, "sys"),
        dia: this.calibAverage(points, "dia"),
      };
    },
    renderBleAnimation() {},
    clean() {
      this.stats2 = {};
      this.loading = false;
      this.episode = {};
      this.stats = {
        pulse: null,
        sys: null,
        dia: null,
      };
    },
  },
};
</script>

<style lang="scss">
@import "../profile/components/chart.scss";
</style>
