<template>
  <v-container class="pa-0 ma-0">
    <v-btn
      icon
      color="white"
      style="top: 10px; z-index: 20; position: absolute; right: 20px"
      @click="generateReport"
      :loading="downloading"
      :disabled="!showDownloading"
    >
      <v-icon>mdi-download</v-icon>
    </v-btn>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="$printOptions.enableDownload"
      :preview-modal="$printOptions.previewModal"
      :pdf-quality="2"
      :manual-pagination="true"
      ref="html2Pdf"
      pdf-content-width="560px"
      :html-to-pdf-options="printOptions"
      @beforeDownload="downloading = true"
      @hasDownloaded="hasDownloaded"
    >
      <section slot="pdf-content">
        <div class="printing_container">
          <EpisodeDetail :episodeId="episodeId" />
        </div>
      </section>
    </vue-html2pdf>
    <lottie-loader
      :styleCss="{ 'margin-top': '4px', 'margin-left': 'calc(50% - 130px)' }"
      :url="'/lottie/robot.json'"
      :width="'260px'"
      v-if="!episodeId"
    />
    <EpisodeDetail v-else :episodeId="episodeId" ref="EpisodeDetail" />
    <h3 style="color: #fafafa">
      <v-icon style="color: #fafafa">mdi-chart-line</v-icon>
      {{ `${$t("common.doctorNote")}` }}:
    </h3>
    <text-editor v-model="verification.note" class="white--text" />
    <div class="d-flex mt-2 mb-1">
      <v-spacer />
      <v-btn class="mt-5 custom pa-6 ml-2 mr-2" @click="saveVerification()">
        {{ $t("common.save") }} {{ $t("common.doctorNote") }}
      </v-btn>
      <v-spacer />
    </div>
    <div class="d-flex mt-2 mb-1 pb-8">
      <v-spacer />
      <v-btn
        class="mt-5 custom pa-6 ml-2 mr-2"
        style="
          min-width: 150px;
          min-width: 120px;
          background-color: #00bcd4 !important;
        "
        @click="saveVerification(3)"
      >
        <v-icon class="mr-1">mdi-chevron-down</v-icon>
        {{ $t("common.low") }}
      </v-btn>
      <v-btn
        class="mt-5 custom pa-6 ml-2 mr-2"
        style="min-width: 150px; background-color: #4caf50 !important"
        @click="saveVerification(1)"
      >
        <v-icon class="mr-1">mdi-check</v-icon>
        {{ $t("common.normal") }}
      </v-btn>
      <v-btn
        class="mt-5 custom pa-6 ml-2 mr-2"
        style="min-width: 150px; background-color: #ff9800 !important"
        @click="saveVerification(2)"
      >
        <v-icon class="mr-1">mdi-chevron-up</v-icon>
        {{ $t("common.high") }}
      </v-btn>
      <v-spacer />
    </div>
  </v-container>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import EpisodeDetail from "@/views/history/EpisodeDetail";
import LottieLoader from "@/components/LottieLoader.vue";
import TextEditor from "@/components/ckeditor/TextEditor.vue";
import moment from "moment";

export default {
  components: {
    VueHtml2pdf,
    EpisodeDetail,
    LottieLoader,
    TextEditor,
  },
  props: {
    episodeId: {
      type: String,
      default: null,
    },
    uid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      verification: {
        category: 0,
        at: null,
        by: null,
        note: "",
        episodeId: null,
      },
      printOptions: {
        margin: 0,
        filename: `report.pdf`,
        image: {
          type: "jpeg",
          quality: 1,
        },
        enableLinks: true,
        html2canvas: {
          scale: 1,
          useCORS: true,
        },
        jsPDF: {
          unit: "in",
          format: "a5",
          orientation: "portrait",
        },
      },
      downloading: false,
      showDownloading: false,
    };
  },
  mounted() {
    this.renderVerification();
    setTimeout(() => {
      this.showDownloading = true;
    }, 5000);
  },
  methods: {
    async renderVerification() {
      var verification = await this.$dbGet(
        `episodes/${this.episodeId}/verification`
      );
      if (!verification) return;
      this.verification = verification;
    },
    async saveVerification(category = 0) {
      if (category) this.verification.category = category;
      this.verification.episodeId = this.episodeId;
      this.verification.at = new moment().format();
      this.verification.by = this.$user;
      this.verification.note += `<p><b>${
        this.$user.fullName
      }</b> - ${moment().format("YYYY/MM/DD HH:mm")} - ${
        this.verification.category == 1 ? "Bình thường" : ""
      }${this.verification.category == 2 ? "Cao" : ""}${
        this.verification.category == 3 ? "Thấp" : ""
      }</p>`;
      await this.$dbSet(
        `episodes/${this.episodeId}/verification`,
        this.verification
      );
      if (this.uid) {
        await this.$dbSet(
          `users/${this.uid}/episodes/${this.episodeId}/verification`,
          this.verification
        );
      }
      this.showSuccess(this.$t("common.successMessage"));
      this.$emit("verified", this.verification);
      this.$refs.EpisodeDetail.renderVerification();
    },
    async generateReport() {
      this.downloading = true;
      await this.sleep(200);
      this.$refs.html2Pdf.generatePdf();
    },
    async hasDownloaded(blob) {
      var url = await this.handleHtml2PdfDownloaded(blob);
      this.downloading = false;
      if (url) this.shareLink(url);
    },
  },
};
</script>

<style lang="scss"></style>
