import moment from "moment";

export var pulseChartOptions = {
  chart: {
    backgroundColor: "transparent",
    zoomType: "x",
    panning: true,
    panKey: "shift",
  },

  title: {
    text: "",
  },

  xAxis: {
    type: "datetime",
    lineWidth: 0,
    labels: {
      style: {
        color: "#e0e0e0",
        fontSize: "0.6rem",
      },
      formatter: function () {
        return `${moment(this.value).format("HH")}h<br/> ${moment(
          this.value
        ).format("mm:ss")}`;
      },
    },
  },

  yAxis: {
    title: {
      text: null,
    },
    gridLineColor: "transparent",
    labels: {
      style: {
        color: "#e0e0e0",
        fontSize: "0.6rem",
      },
    },
    min: 20,
    max: 160,
    plotBands: [
      {
        // Light air
        from: 0,
        to: 60,
        color: "#4DD0E1",
      },
      {
        // Light air
        from: 60,
        to: 120,
        color: "#81C784",
      },
      // {
      //   // Light air
      //   from: 90,
      //   to: 120,
      //   color: "#FFF176",
      // },
      {
        // Light air
        from: 120,
        to: 150,
        color: "#FFB74D",
      },
      {
        // Light air
        from: 150,
        to: 250,
        color: "#F06292",
      },
    ],
  },

  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "112%",
          color: "white",
        },
      },
    },
    line: {
      marker: {
        enabled: true,
      },
    },
  },

  legend: { enabled: false },

  tooltip: {
    crosshairs: true,
    shared: true,
    useHTML: true,
    followTouchMove: true,
    formatter: function () {
      const points = this.points;
      // Get content for each tooltip
      let tooltips = points.map((v, ind) => {
        var { options } = v.point;
        var { low, high, y } = options;
        if (low && high) y = [low, high].sort().reverse().join(" - ");
        var result = "";
        if (ind == 0)
          result += `<span style="font-size:0.6rem;margin:0.5rem;"><b>${moment(
            v.x
          ).format("HH:mm:ss")}</b></span><br/>`;
        result += `
          • ${v.series.name}: <b>${y}</b>
        `;
        return result;
      });
      tooltips = tooltips.join("<br/>");
      // Return array of tooltip content html strings
      return tooltips;
    },
  },

  series: [],
};

export var statsLineChartOptions = {
  chart: {
    backgroundColor: "transparent",
    zoomType: "x",
    panning: true,
    panKey: "shift",
  },
  title: {
    text: "",
  },

  xAxis: {
    lineWidth: 0,
    gridLineColor: "transparent",
    labels: {
      enabled: false,
    },
  },

  yAxis: {
    title: {
      text: null,
    },
    lineWidth: 0,
    gridLineColor: "transparent",
    labels: {
      style: {
        color: "#e0e0e0",
        fontSize: "0.6rem",
      },
    },
    min: 20,
    max: 160,
    plotBands: [
      {
        // Light air
        from: 0,
        to: 60,
        color: "#4DD0E1",
      },
      {
        // Light air
        from: 60,
        to: 120,
        color: "#81C784",
      },
      // {
      //   // Light air
      //   from: 90,
      //   to: 120,
      //   color: "#FFF176",
      // },
      {
        // Light air
        from: 120,
        to: 150,
        color: "#FFB74D",
      },
      {
        // Light air
        from: 150,
        to: 250,
        color: "#F06292",
      },
    ],
  },

  plotOptions: {
    series: {
      dataLabels: {
        enabled: false,
        style: {
          fontSize: "75%",
          color: "white",
        },
      },
    },
    line: {
      marker: {
        enabled: false,
      },
    },
  },

  legend: { enabled: false },

  tooltip: {
    crosshairs: true,
    shared: true,
    useHTML: true,
    followTouchMove: true,
    formatter: function () {
      const points = this.points;
      // Get content for each tooltip
      let tooltips = points.map((v, ind) => {
        var { options } = v.point;
        var { low, high, y } = options;
        if (low && high) y = [low, high].sort().reverse().join(" - ");
        if (y > 100000) y = moment(y).format("DD/MM/YYYY HH:mm");
        var result = "";
        if (ind == 0)
          result += `<span style="font-size:0.6rem;margin:0.5rem;"><b>${v.x}</b></span><br/>`;
        result += `
          • ${v.series.name}: <b>${y}</b>
        `;
        return result;
      });
      tooltips = tooltips.join("<br/>");
      // Return array of tooltip content html strings
      return tooltips;
    },
  },

  series: [],
};

export var statsPieChartOptions = {
  chart: {
    type: "pie",
    backgroundColor: "transparent",
    options3d: {
      enabled: true,
      alpha: 45,
      beta: 0,
    },
  },
  title: {
    text: "",
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      depth: 135,
      dataLabels: {
        enabled: false,
      },
      //colors: ["#00BCD4", "#66BB6A", "#FFA726", "#F06292"],
    },
  },
  series: [],
};

export var bubbleChartOptions = {
  chart: {
    type: "bubble",
    zoomType: "xy",
    backgroundColor: "transparent",
  },
  legend: { enabled: false },
  tooltip: {
    enabled: false,
  },
  yAxis: {
    maxPadding: 0.2,
    min: -1,
    max: 23,
    title: {
      text: "hours",
      style: {
        color: "rgba(255,255,255,0.8)",
      },
    },
    gridLineColor: "rgba(255,255,255,0.5)",
    labels: {
      style: {
        color: "rgba(255,255,255,0.8)",
      },
      formatter: function () {
        var hours = [];
        for (var i = 1; i <= 11; i++) {
          hours.push(i + "am");
        }
        hours.push("12pm");
        for (var j = 1; j <= 11; j++) {
          hours.push(j + "pm");
        }
        hours.push("12am");
        return hours[this.value];
      },
    },
  },
  xAxis: {
    gridLineColor: "rgba(255,255,255,0.5)",
    labels: {
      style: {
        color: "rgba(255,255,255,0.8)",
      },
      formatter: function () {
        return ["Sun", "Mon", "Tue", "Web", "Thu", "Fri", "Sat"][this.value];
      },
    },
  },
  title: {
    text: "",
  },
  series: [],
};

var weekDates = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
var hoursRange = [
  "00 am",
  "01 am",
  "02 am",
  "03 am",
  "04 am",
  "05 am",
  "06 am",
  "07 am",
  "08 am",
  "09 am",
  "10 am",
  "11 am",
  "12 am",
  "13 pm",
  "14 pm",
  "15 pm",
  "16 pm",
  "17 pm",
  "18 pm",
  "19 pm",
  "20 pm",
  "21 pm",
  "22 pm",
  "23 pm",
];

export var heatmapChartOptions = {
  chart: {
    type: "heatmap",
    plotBorderWidth: 0,
    backgroundColor: "transparent",
    zoomType: "x",
    panning: true,
    panKey: "shift",
  },

  title: {
    text: "",
  },

  yAxis: {
    categories: hoursRange,
    title: null,
    reversed: false,
    lineWidth: 1,
    // gridLineColor: "transparent",
    gridLineColor: "rgba(255,255,255,0.3)",
    labels: {
      style: {
        color: "rgba(255,255,255,0.8)",
      },
    },
  },

  xAxis: {
    categories: weekDates,
    title: null,
    reversed: false,
    lineWidth: 1,
    gridLineColor: "rgba(255,255,255,0.5)",
    labels: {
      style: {
        color: "rgba(255,255,255,0.8)",
      },
    },
  },

  colorAxis: {
    min: 120,
    max: 140,
    stops: [
      [0, "#66BB6A"],
      [0.5, "#FFA726"],
      [1, "#F06292"],
    ],
    // colors: ["#00BCD4", "#66BB6A", "#FFA726", "#F06292"],
  },

  legend: {
    align: "right",
    layout: "vertical",
    margin: 0,
    verticalAlign: "top",
    y: 25,
    x: 20,
    symbolHeight: 280,
    itemStyle: {
      color: "#F06292",
    },
    // enabled: false,
  },

  tooltip: {
    formatter: function () {
      return `${weekDates[this.point.x]}, ${this.point.y} - ${
        this.point.y + 1
      }${this.point.y < 13 ? "am" : "pm"}: <b>${this.point.value}</b>`;
    },
  },

  series: [
    {
      name: "Heatmap",
      borderWidth: 0,
      data: [],
      // dataLabels: {
      //   enabled: true,
      //   color: "#000000",
      // },
    },
  ],

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          yAxis: {
            labels: {
              formatter: function () {
                return this.value.charAt(0);
              },
            },
          },
        },
      },
    ],
  },
};
