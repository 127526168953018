<template>
  <v-container class="pa-0 ma-0">
    <v-row justify="center" align="center">
      <v-col
        class="main_container"
        xl="3"
        lg="4"
        md="5"
        sm="12"
        style="height: calc(100vh - 60px); overflow-y: scroll"
      >
        <v-container fill-height>
          <list-item @view="handleView" @uid="handleUid" ref="ListItem" />
        </v-container>
      </v-col>
      <v-col
        class="main_container"
        xl="5"
        lg="6"
        md="7"
        sm="12"
        style="height: calc(100vh - 60px); overflow-y: scroll"
      >
        <v-container fill-height>
          <item-detail
            v-if="episodeId"
            :episodeId="episodeId"
            :uid="uid"
            @verified="handleVerified"
          />
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ItemDetail from "./components/ItemDetail.vue";
import ListItem from "./components/ListItem.vue";

export default {
  components: { ListItem, ItemDetail },
  data() {
    return {
      episodeId: null,
      uid: null,
    };
  },
  methods: {
    async handleView(episodeId) {
      this.episodeId = null;
      await this.sleep(200);
      this.episodeId = episodeId;
    },
    handleUid(uid) {
      this.uid = uid;
    },
    handleVerified(obj = {}) {
      this.$refs.ListItem.updateVerification(obj);
    },
  },
};
</script>

<style lang="scss">
.custom-text-field {
  position: fixed;
  top: 15px;
  z-index: 100;
  width: 200px;
  left: calc(50% - 100px);
  input {
    color: #bdbdbd !important;
    text-align: center !important;
  }
}
</style>
