<template>
  <div>
    <v-icon
      :tooltip="$t('common.low')"
      v-if="category == 3"
      style="color: #00bcd4 !important"
      :style="{ fontSize: fontSize }"
      >mdi-chevron-down</v-icon
    >
    <v-icon
      :tooltip="$t('common.normal')"
      v-if="category == 1"
      style="color: #4caf50 !important"
      :style="{ fontSize: fontSize }"
      >mdi-check</v-icon
    >
    <v-icon
      :tooltip="$t('common.high')"
      v-if="category == 2"
      style="color: #ff9800 !important"
      :style="{ fontSize: fontSize }"
      >mdi-chevron-up</v-icon
    >
    <span v-if="category && showText" :style="{ lineHeight: fontSize }">
      {{ category == 1 ? $t("common.normal") : "" }}
      {{ category == 2 ? $t("common.high") : "" }}
      {{ category == 3 ? $t("common.low") : "" }}
    </span>
  </div>
</template>

<script>
export default {
  name: "VerificationIcon",
  props: {
    showText: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: "1.2rem",
    },
    category: {
      type: Number,
      default: 0,
    },
  },
};
</script>
