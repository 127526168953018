<template>
  <v-container class="pa-0 ma-0">
    <v-text-field
      filled
      outlined
      class="custom-text-field search_custom"
      color="grey lighten-1"
      hide-details
      clearable
      dense
      v-model="phone"
      @change="getEpisodes"
    ></v-text-field>
    <div class="d-flex">
      <div class="select-custom mr-1">
        <v-select
          v-model="filter.category"
          filled
          outlined
          required
          hide-details
          :items="categoryOptions"
          dense
          @change="applyFilter"
        ></v-select>
      </div>
      <div class="select-custom ml-1">
        <v-select
          v-model="filter.mode"
          filled
          outlined
          required
          hide-details
          :items="modeOptions"
          dense
          @change="applyFilter"
        ></v-select>
      </div>
    </div>
    <v-text-field
      filled
      outlined
      :label="$t('common.search') + '...'"
      color="grey lighten-1"
      class="search_custom"
      style="margin-top: -10px; margin-bottom: 10px"
      hide-details
      clearable
      dense
      v-model="filter.searchTerm"
      @change="applyFilter"
    ></v-text-field>
    <div style="text-align: center" v-if="loading" class="mt-2">
      <v-progress-circular indeterminate color="#fefefe"></v-progress-circular>
    </div>
    <div
      v-else
      class="content_card"
      style="
        min-height: calc(100vh - 188px);
        display: block;
        padding-bottom: 0px;
      "
    >
      <h5 v-if="!items.length" class="no_data">
        {{ $t("common.nodata") }}
      </h5>
      <content-item
        v-else
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        @view="view(item)"
      >
        <template v-slot:controller>
          <v-list-item-icon class="pt-4 pr-1" v-if="item.category">
            <verification-icon :category="item.category" />
          </v-list-item-icon>
          <v-list-item-icon class="pt-4 pr-1">
            <span class="mr-2" style="font-size: 0.9rem; margin-top: 2px">{{
              item.mode ? "24H" : "M"
            }}</span>
          </v-list-item-icon>
        </template>
      </content-item>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import ContentItem from "@/components/cards/ContentItem";
import VerificationIcon from "./VerificationIcon.vue";
export default {
  components: { ContentItem, VerificationIcon },
  computed: {
    modeOptions() {
      return [
        {
          text: this.$t("common.allData"),
          value: null,
        },
        {
          text: this.$t("common.manualMode"),
          value: 1,
        },
        {
          text: "24H",
          value: 2,
        },
      ];
    },
    categoryOptions() {
      return [
        {
          text: this.$t("common.all") + " " + this.$t("common.bloodPressure"),
          value: null,
        },
        {
          text: this.$t("common.uncategorized"),
          value: 0,
        },
        {
          text:
            this.$t("common.normal") + " " + this.$t("common.bloodPressure"),
          value: 1,
        },
        {
          text: this.$t("common.high") + " " + this.$t("common.bloodPressure"),
          value: 2,
        },
        {
          text: this.$t("common.low") + " " + this.$t("common.bloodPressure"),
          value: 3,
        },
      ];
    },
  },
  data() {
    return {
      loading: true,
      phone: "0377123497",
      items: [],
      collections: [],
      filter: {
        mode: null, // 0: all, 1: manual, 2: 24h
        category: null, // 0: all, 1: Normal, 2: High, 3: Low
        searchTerm: "",
      },
    };
  },
  async mounted() {
    await this.getEpisodes();
  },
  methods: {
    async applyFilter() {
      var items = JSON.parse(JSON.stringify(this.collections));
      if (this.filter.mode == 1) {
        items = items.filter((e) => !e.mode);
      } else if (this.filter.mode == 2) {
        items = items.filter((e) => !!e.mode);
      }
      if (this.filter.category) {
        items = items.filter((e) => e.category == this.filter.category);
      } else if (this.filter.category == 0) {
        items = items.filter((e) => !e.category);
      }
      if (this.filter.searchTerm) {
        items = items.filter((e) =>
          e.title.toLowerCase().includes(this.filter.searchTerm.toLowerCase())
        );
      }
      this.items = items;
    },
    updateVerification(verification) {
      var index = this.items.findIndex((e) => e.id == verification.episodeId);
      if (index < 0) return;
      this.items[index].category = verification.category;
      this.items[index].item.verification = verification;
      var index2 = this.collections.findIndex(
        (e) => e.id == verification.episodeId
      );
      if (index2 < 0) return;
      this.collections[index2].category = verification.category;
      this.collections[index2].item.verification = verification;
      this.$forceUpdate();
    },
    async getEpisodes() {
      this.loading = true;
      this.items = [];
      this.collections = [];
      var uid = await this.$dbGet(`patients/${this.phone}/id`);
      if (!uid) {
        this.loading = false;
        return;
      }
      this.$emit("uid", uid);
      var episodes = await this.$dbGet(`users/${uid}/episodes`);
      if (!episodes) {
        this.loading = false;
        return;
      }
      episodes = Object.values(episodes)
        .map((e) => ({
          ...e,
          title: e.name,
          category: (e.verification && e.verification.category) || 0,
          mode: e.config && e.config.calibsPerHour,
          content: `
              <span class="mt-2" style="font-size:0.85rem;">
                ${this.$t("common.date")} ${moment(e.date).format(
            "DD-MM-YYYY HH:mm"
          )}
              </span>
              `,
          message: `
              <i aria-hidden="true" class="v-icon notranslate mdi mdi-gauge theme--light" style="font-size: 0.9rem;"></i>
              <span style="font-size:0.8rem;">
                ${e.config && e.config.maxInflatePressure}
              </span>
              <span style="font-size:0.8rem;">mmHg</span>
              <i aria-hidden="true" class="ml-2 v-icon notranslate mdi mdi-heart-outline theme--light" style="font-size: 0.9rem;"></i>
              <span style="font-size:0.8rem;">
                ${e.datacounts || 0}
              </span>
              <i aria-hidden="true" class="ml-2 v-icon notranslate mdi mdi-repeat theme--light" style="font-size: 0.9rem;"></i>
              ${
                e.config && e.config.calibsPerHour
                  ? `<span style="font-size:0.8rem;">
                      ${e.config && e.config.calibsPerHour} ${this.$t(
                      "common.times"
                    )} / h
                    </span>`
                  : `<span style="font-size:0.8rem;">
                      ${this.$t("common.manualMode")}
                    </span>`
              }`,
          image: false,
          item: e,
        }))
        .reverse();

      this.items = episodes;
      this.collections = JSON.parse(JSON.stringify(episodes));
      this.loading = false;
    },
    view({ id }) {
      this.$emit("view", id);
    },
  },
};
</script>

<style lang="scss">
.search_custom {
  background-color: #313959;
  border-radius: 12px !important;
  label,
  input {
    margin-top: 0px !important;
    color: #e0e0e0 !important;
  }
}
</style>
