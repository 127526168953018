<template>
  <table class="badges">
    <tr>
      <th>{{ $t("common.low") }}</th>
      <td>
        <v-badge offset-y="-1" color="cyan"></v-badge>
        <b class="ml-4">{{ value.low.count || 0 }}</b>
      </td>
      <td v-if="value.low.sys">
        S: <b>{{ value.low.sys }}</b>
      </td>
      <td v-if="value.low.dia">
        D: <b>{{ value.low.dia }}</b>
      </td>
      <td v-if="value.low.pulse">
        <v-icon small>mdi-heart-outline</v-icon>
        <b> {{ value.low.pulse }}</b>
      </td>
    </tr>

    <tr>
      <th>{{ $t("common.normal") }}</th>
      <td>
        <v-badge offset-y="-1" color="green"></v-badge>
        <b class="ml-4">{{ value.normal.count || 0 }}</b>
      </td>
      <td v-if="value.normal.sys">
        S: <b>{{ value.normal.sys }}</b>
      </td>
      <td v-if="value.normal.dia">
        D: <b>{{ value.normal.dia }}</b>
      </td>
      <td v-if="value.normal.pulse">
        <v-icon small>mdi-heart-outline</v-icon>
        <b> {{ value.normal.pulse }}</b>
      </td>
    </tr>

    <tr>
      <th>{{ $t("common.high") }}</th>
      <td>
        <v-badge offset-y="-1" color="orange"></v-badge>
        <b class="ml-4">{{ value.high.count || 0 }}</b>
      </td>
      <td v-if="value.high.sys">
        S: <b>{{ value.high.sys }}</b>
      </td>
      <td v-if="value.high.dia">
        D: <b>{{ value.high.dia }}</b>
      </td>
      <td v-if="value.high.pulse">
        <v-icon small>mdi-heart-outline</v-icon>
        <b> {{ value.high.pulse }}</b>
      </td>
    </tr>

    <tr>
      <th>{{ $t("common.veryHigh") }}</th>
      <td>
        <v-badge offset-y="-1" color="pink"></v-badge>
        <b class="ml-4">{{ value.veryHigh.count || 0 }}</b>
      </td>
      <td v-if="value.veryHigh.sys">
        S: <b>{{ value.veryHigh.sys }}</b>
      </td>
      <td v-if="value.veryHigh.dia">
        D: <b>{{ value.veryHigh.dia }}</b>
      </td>
      <td v-if="value.veryHigh.pulse">
        <v-icon small>mdi-heart-outline</v-icon>
        <b> {{ value.veryHigh.pulse }}</b>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
};
</script>
